.selected {
  margin-right: 8px;
}

.selected__label-container {
  margin-bottom: 6px;
  font-weight: 600;
}

.selected__label {
  font-family: "Work Sans";
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.08px;
  color: #6b6b6b;
  margin-bottom: 7px;
}

.css-1uccc91-singleValue {
  padding-left: 20px;
}

.css-1wa3eu0-placeholder {
  padding-left: 20px;
}

.selected__selected {
  position: relative;
  margin-top: 7px;
}

.selected__img {
  position: absolute;
  z-index: 1;
  top: 6px;
  left: 5px;
}

@media (max-width: 768px) {
  .selected {
      margin: 5px 10px 5px 5px;
  }
}

@media (max-width: 425px) {
  .selected {
      margin: 5px 0 5px 5px;
  }
}