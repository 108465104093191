body{
  background-color: #f8f9ff;
}

body * {
  font-family: 'Work Sans', sans-serif;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 72px;
  font-weight: 700;
  line-height: 44px;
  font-weight: bold;
  color: white;
  text-align: center;
}

.section_title {
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.16px;
  color: #000000;
}

.image_powered {
  float: right;
  object-fit: contain;
  width: 150px;
}

.w-100 {
  width: 100%;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #4a4a4a;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
}

p {
  margin-bottom: 10px;
}

a {
  text-decoration: none;
}

.app-content{
  clear: both;
  max-width: 1170px;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: block;
  flex-wrap: wrap;
}

.app-content h1{
  margin-bottom: 120px;
}

@media screen and (max-width: 500px) {
  .image_powered {
    width: 63px;
  }
}

@media screen and (max-width: 1024px) {
  
  .desk{
    display: none;
  }
  .app-content h1 {
    color: black;
    line-height: 46px;
    font-size: 32px;
    margin: 55px 0 5px 0;
  }
  /* header {
    line-height: 30px;
    padding-left: 0;
    margin-top: 14px;
  }
  header .logo-line {
    margin: 0 auto;
  }
  header .nav-link,
  header .nav-menu {
    float: none;
  }
  header nav {
    padding-right: 0;
    text-align: center;
  } */
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #6b6b6b;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 766px) {
  .app-content {
    min-width: 300px;
    max-width: 95vw;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .app-content {
    min-width: 700px;
    max-width: 95vw;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1025px) {
  .app-content {
    min-width: 1000px;
    max-width: 95vw;
  }
}
